import { useMutation, useQuery } from '@apollo/client'
import { FormikProps } from 'formik'
import useToast, { ToastType } from '../../../hooks/useToast'
import {
  publishPageContentMutation,
  upsertPageContentMutation
} from '../mutation'
import {
  getPageContentByEvent,
  getPublishedPageContentByEvent,
  useCreateEventAgendaPDFMutation,
  IGetPageContentByEventQuery,
  IGetPublishedPageContentByEventQuery
} from '../query'
import { IEditorState } from '../types'
import { getInitialValues, transformPayloadToEditorState } from '../utils'
import useTranslation from '../../../hooks/useTranslation'

export const usePublishPageContent = (id?: string) => {
  const mutation = useMutation(publishPageContentMutation, {
    update(cache, result) {
      cache.updateQuery<IGetPublishedPageContentByEventQuery>(
        {
          query: getPublishedPageContentByEvent,
          variables: { id }
        },
        data => {
          const newData = {
            ...data,
            publishedContent: {
              ...result.data!.publishPageContent
            }
          }
          return newData
        }
      )
    }
  })
  return mutation
}

export const useUpsertPageContent = (id?: string) => {
  const mutation = useMutation(upsertPageContentMutation, {
    update(cache, result) {
      cache.updateQuery<IGetPageContentByEventQuery>(
        {
          query: getPageContentByEvent,
          variables: { id }
        },
        data => {
          const newData = {
            ...data!,
            draftContent: {
              ...data?.draftContent,
              ...result.data.upsertPageContent!
            }
          }
          return newData
        }
      )
    }
  })

  return mutation
}

export const useCreateEventAgendaPDF = () => {
  const [createEventAgendaPDF, { data, loading, error }] = useMutation(
    useCreateEventAgendaPDFMutation
  )
  const { showToast } = useToast()
  const { t } = useTranslation()
  const createEventPDF = async id => {
    if (!id) {
      showToast(t('summits:editor:messages:pdfSaveFail'), ToastType.ERROR)
      throw new Error('ID is required to create the event PDF.')
    }

    try {
      const response = await createEventAgendaPDF({ variables: { id } })
      return response?.data
    } catch (err) {
      console.error('Error creating event PDF:', err)
      throw err
    }
  }

  return {
    createEventPDF,
    data,
    loading,
    error
  }
}

export const useGetPageContent = (
  form: FormikProps<IEditorState>,
  id?: string
) => {
  const { showToast } = useToast()
  const { data, loading, error } = useQuery<IGetPageContentByEventQuery>(
    getPageContentByEvent,
    {
      variables: { id },
      skip: !id,
      fetchPolicy: 'network-only',
      onCompleted(data) {
        const pageContent = data.draftContent
        if (pageContent) {
          try {
            const editorState = transformPayloadToEditorState(pageContent)

            form.resetForm({ values: editorState })
          } catch (error) {
            console.log(error)
          }
        } else {
          const editorState = getInitialValues(data.event!)
          form.resetForm({ values: editorState })
        }
      },
      onError(error) {
        showToast(error.message, ToastType.ERROR)
      }
    }
  )

  const {
    data: dataPublished
  } = useQuery<IGetPublishedPageContentByEventQuery>(
    getPublishedPageContentByEvent,
    {
      variables: { id },
      skip: !id,
      fetchPolicy: 'network-only'
    }
  )

  const lastSaved = data?.draftContent?.updatedAt
    ? new Date(data.draftContent.updatedAt)
    : undefined
  const lastPublish =
    dataPublished?.publishedContent?.updatedAt &&
    dataPublished.publishedContent.publishDate
      ? new Date(dataPublished.publishedContent.updatedAt)
      : undefined
  const lastUnpublish =
    dataPublished?.publishedContent?.updatedAt &&
    !dataPublished.publishedContent.publishDate
      ? new Date(dataPublished.publishedContent.updatedAt)
      : undefined

  return [
    { data, loading, error, dataPublished },
    { lastSaved, lastPublish, lastUnpublish }
  ]
}
